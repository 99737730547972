import React from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';

const AppBar = (): JSX.Element => {
  return (
    <Disclosure as="nav" className="bg-dataops-dark h-16 w-screen fixed top-0 z-40">
      <div className="w-full h-full pl-[0.8rem] pr-2 sm:pr-4 lg:pr-8">
        <div className="w-full h-full relative flex items-center">
          <div className="flex-grow flex items-center px-2 lg:px-0">
            <div className="flex-shrink-0 pl-6">
              <NavLink to="/">
                <img className="h-8 w-auto" src="/static/dataops-logo-with-tagline.svg" alt="DataOps.live" />
              </NavLink>
            </div>
            <div className="ml-6">
              <div className="flex items-center space-x-3">
                <div className="px-3 text-sm text-gray-100 font-semibold border-l border-secondary-text">
                  Sharing Service
                </div>
              </div>
            </div>
          </div>
          <div className="ml-4">
            <div className="flex items-center">{/* Profile dropdown */}</div>
          </div>
        </div>
      </div>
    </Disclosure>
  );
};

export default AppBar;
