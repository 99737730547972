import ProjectBreadcrumb from 'components/ProjectBreadcrumb';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { FolderIcon, ShareIcon } from '@heroicons/react/24/outline';

export interface ListingItem {
  name: string;
  type: string;
  contents?: ListingItem[];
}

export interface ListingItemFileContent {
  ancestors: { [x: string]: string };
  projectName: string;
  projectLogoUrl: string;
  contents: ListingItem[];
  inProgress: boolean;
}
// interface ListingSummary {
//   type: string;
//   directories: number;
//   files: number;
// }

export default function ProjectListing(): JSX.Element {
  console.log('');
  console.log('ProjectListing()');

  const { ancestors, projectName, projectLogoUrl, contents, inProgress } = useLoaderData() as ListingItemFileContent;

  if (inProgress) {
    return <div>Still generating shareable files</div>;
  }

  return (
    <div className="container mt-4 p-2">
      <div className="flex mb-6 shadow border-b border-grey-200 p-4 bg-dataops-table-header">
        <>
          <div className="border-b border-neutral-200 transition duration-400 ease-in-out rounded shadow ">
            <img className="inline-block h-12 w-12 rounded" src={projectLogoUrl} />
          </div>
          <h1 className="ml-4 my-2">
            <p className="text-l font-large text-gray-700 group-hover:text-gray-900 h-8 w-5">{projectName}</p>
          </h1>
        </>
        <div className="ml-auto">
          <button
            className="flex items-center min-w-[5rem] h-8 border rounded border-gray-400 px-3 py-2 text-sm hover:bg-neutral-100 hover:shadow-inner"
            onClick={() => {
              console.log('Share button clicked');
            }}
          >
            <span className="mr-1">
              <ShareIcon className="h-4 w-4 text-gray-600" />
            </span>
            Share
          </button>
        </div>
      </div>

      <ProjectBreadcrumb ancestors={ancestors} />
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full">
            <div className="overflow-hidden border border-neutral-200 rounded">
              <table className=" min-w-full text-left text-sm font-light text-surface ">
                <thead className="border-b border-neutral-200 bg-dataops-table-header">
                  <tr>
                    <th scope="col" className="px-4 py-[10px]">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-[10px]">
                      Last commit
                    </th>
                    <th scope="col" className="px-4 py-[10px]">
                      Last update
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contents
                    .filter(function (item) {
                      return item.type === 'directory';
                    })
                    .sort(function (a, b) {
                      return a.name.localeCompare(b.name);
                    })
                    .map((item, index) => (
                      <tr
                        key={index}
                        className="border-b border-neutral-200 transition duration-400 ease-in-out hover:bg-neutral-200 "
                      >
                        <td className="whitespace-nowrap px-4 py-[10px]">
                          <a href={item.name + '/'} className="flex items-center">
                            <span className="mr-1">
                              <FolderIcon className="h-4 w-4 text-neutral-600" />
                            </span>
                            {item.name}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-4 py-[10px]">-</td>
                        <td className="whitespace-nowrap px-4 py-[10px]">-</td>
                      </tr>
                    ))}
                  {contents
                    .filter(function (item) {
                      return item.type === 'file';
                    })
                    .sort(function (a, b) {
                      return a.name.localeCompare(b.name);
                    })
                    .map((item, index) => (
                      <tr
                        key={index}
                        className="border-b border-neutral-200 transition duration-400 ease-in-out hover:bg-neutral-200 "
                      >
                        <td className="whitespace-nowrap px-4 py-[10px]">{item.name}</td>
                        <td className="whitespace-nowrap px-4 py-[10px]">-</td>
                        <td className="whitespace-nowrap px-4 py-[10px]">-</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
