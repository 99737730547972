import React from 'react';

interface ProjectBreadcrumbProps {
  ancestors: { [x: string]: string };
}

const ProjectBreadcrumb = (props: ProjectBreadcrumbProps): JSX.Element => {
  return (
    <div className="w-full mb-4 text-sm">
      <ol className="list-reset flex">
        {Object.keys(props.ancestors).map((key, index) => (
          <span key={index} className="flex">
            <li>
              <a href={props.ancestors[key]} className="hover:underline">
                {key}
              </a>
              <span className="mx-2">/</span>
            </li>
          </span>
        ))}
      </ol>
    </div>
  );
};

export default ProjectBreadcrumb;
