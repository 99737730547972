import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import NotFoundView from 'views/NotFoundView';

export interface RouteError {
  status: number;
  message: string;
}

export function RootBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.statusText === 'InProgress') {
      return <div>Still generating shareable files</div>;
    } else {
      return <NotFoundView />;
    }
  }

  return <div>Something went wrong</div>;
}
