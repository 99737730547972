export const testManifest = [
  { type: 'directory', name: '.devcontainer', contents: [{ type: 'file', name: 'devcontainer.json' }] },
  { type: 'file', name: '.gitignore' },
  { type: 'file', name: '.gitpod.yml' },
  { type: 'file', name: 'PREVIEW.md' },
  { type: 'file', name: 'README.md' },
  { type: 'file', name: 'catalog.json' },
  {
    type: 'directory',
    name: 'dataops',
    contents: [
      { type: 'file', name: 'README.md' },
      {
        type: 'directory',
        name: 'modelling',
        contents: [
          { type: 'file', name: '.gitignore' },
          { type: 'file', name: '.skip_dde_setup' },
          { type: 'file', name: 'dbt_project.yml' },
          {
            type: 'directory',
            name: 'macros',
            contents: [
              { type: 'file', name: 'generate_schema_name.sql' },
              { type: 'file', name: 'grant_table_ownership.sql' },
            ],
          },
          {
            type: 'directory',
            name: 'models',
            contents: [
              {
                type: 'directory',
                name: 'analytics',
                contents: [
                  { type: 'file', name: 'analytics_customer_loyalty_metrics_v.sql' },
                  { type: 'file', name: 'analytics_customer_loyalty_metrics_v.yml' },
                  { type: 'file', name: 'analytics_location_detail_v.sql' },
                  { type: 'file', name: 'analytics_location_detail_v.yml' },
                  { type: 'file', name: 'analytics_orders_v.sql' },
                  { type: 'file', name: 'analytics_orders_v.yml' },
                  { type: 'file', name: 'analytics_shift_sales.py' },
                ],
              },
              {
                type: 'directory',
                name: 'harmonized',
                contents: [
                  { type: 'file', name: 'harmonized_customer_loyalty_metrics_v.sql' },
                  { type: 'file', name: 'harmonized_location_detail_v.sql' },
                  { type: 'file', name: 'harmonized_orders_v.sql' },
                ],
              },
              { type: 'file', name: 'overview.md' },
              {
                type: 'directory',
                name: 'raw_customer',
                contents: [{ type: 'file', name: 'raw_customer_customer_loyalty.sql' }],
              },
              {
                type: 'directory',
                name: 'raw_pos',
                contents: [
                  { type: 'file', name: 'raw_pos_country.sql' },
                  { type: 'file', name: 'raw_pos_franchise.sql' },
                  { type: 'file', name: 'raw_pos_location.sql' },
                  { type: 'file', name: 'raw_pos_menu.sql' },
                  { type: 'file', name: 'raw_pos_order_detail.sql' },
                  { type: 'file', name: 'raw_pos_order_header.sql' },
                  { type: 'file', name: 'raw_pos_truck.sql' },
                ],
              },
              {
                type: 'directory',
                name: 'raw_safegraph',
                contents: [{ type: 'file', name: 'raw_safegraph_core_poi_geometry.sql' }],
              },
            ],
          },
          { type: 'file', name: 'packages.yml' },
          { type: 'directory', name: 'seeds', contents: [{ type: 'file', name: '.gitkeep' }] },
          {
            type: 'directory',
            name: 'sources',
            contents: [
              { type: 'file', name: '.gitkeep' },
              {
                type: 'directory',
                name: 'frostbyte_tasty_bytes',
                contents: [
                  {
                    type: 'directory',
                    name: 'raw_customer',
                    contents: [{ type: 'file', name: 'customer_loyalty.yml' }],
                  },
                  {
                    type: 'directory',
                    name: 'raw_pos',
                    contents: [
                      { type: 'file', name: 'country.yml' },
                      { type: 'file', name: 'franchise.yml' },
                      { type: 'file', name: 'location.yml' },
                      { type: 'file', name: 'menu.yml' },
                      { type: 'file', name: 'order_detail.yml' },
                      { type: 'file', name: 'order_header.yml' },
                      { type: 'file', name: 'truck.yml' },
                    ],
                  },
                  {
                    type: 'directory',
                    name: 'raw_safegraph',
                    contents: [{ type: 'file', name: 'core_poi_geometry.yml' }],
                  },
                ],
              },
            ],
          },
        ],
      },
      { type: 'directory', name: 'profiles', contents: [{ type: 'file', name: 'README.md' }] },
      {
        type: 'directory',
        name: 'snowflake',
        contents: [
          { type: 'file', name: 'account.template.yml' },
          { type: 'file', name: 'databases.template.yml' },
          { type: 'file', name: 'roles.template.yml' },
          { type: 'file', name: 'sole-limits.template.yml' },
          { type: 'file', name: 'warehouses.template.yml' },
        ],
      },
      {
        type: 'directory',
        name: 'solution_homepage',
        contents: [
          { type: 'file', name: '.gitignore' },
          { type: 'file', name: 'CONTRIBUTOR.md' },
          {
            type: 'directory',
            name: 'docs',
            contents: [
              { type: 'file', name: '1-zero-to-snowflake-financial-gov.md' },
              { type: 'file', name: '10-react-app.md' },
              { type: 'file', name: '2-zero-to-snowflake-transformation.md' },
              { type: 'file', name: '3-zero-to-snowflake-semi-structured-data.md' },
              { type: 'file', name: '4-zero-to-snowflake-data-gov.md' },
              { type: 'file', name: '5-zero-to-snowflake-collaboration.md' },
              { type: 'file', name: '6-zero-to-snowflake-geospatial.md' },
              { type: 'file', name: '7-zero-to-snowflake-data-eng-deep-dive.md' },
              { type: 'file', name: '8-zero-to-snowflake-voice-of-the-customer.md' },
              { type: 'file', name: '9-data-science-snowpark-101.md' },
              {
                type: 'directory',
                name: 'img',
                contents: [
                  { type: 'file', name: 'DDE_credentials.png' },
                  { type: 'file', name: 'chart1.png' },
                  { type: 'file', name: 'chart10.png' },
                  { type: 'file', name: 'chart11.png' },
                  { type: 'file', name: 'chart12.png' },
                  { type: 'file', name: 'chart13.png' },
                  { type: 'file', name: 'chart14.png' },
                  { type: 'file', name: 'chart15.png' },
                  { type: 'file', name: 'chart16.png' },
                  { type: 'file', name: 'chart17.png' },
                  { type: 'file', name: 'chart18.png' },
                  { type: 'file', name: 'chart2.png' },
                  { type: 'file', name: 'chart3.png' },
                  { type: 'file', name: 'chart4.png' },
                  { type: 'file', name: 'chart5.png' },
                  { type: 'file', name: 'chart6.png' },
                  { type: 'file', name: 'chart7.png' },
                  { type: 'file', name: 'chart8.png' },
                  { type: 'file', name: 'chart9.png' },
                  { type: 'file', name: 'clicktoopenindol.png' },
                  { type: 'file', name: 'codelabexample.png' },
                  { type: 'file', name: 'createawsrole1.jpeg' },
                  { type: 'file', name: 'dashboard1.png' },
                  { type: 'file', name: 'dashboard2.png' },
                  { type: 'file', name: 'dashboard3.png' },
                  { type: 'file', name: 'dashboard4.png' },
                  { type: 'file', name: 'dashboard5.png' },
                  { type: 'file', name: 'dataops-dde-exporer-sentiment-setup-file.png' },
                  { type: 'file', name: 'dataops-dde-sentiment-setup-green-tick.png' },
                  { type: 'file', name: 'dataops-dde-sentiment-setup-run-all.png' },
                  { type: 'file', name: 'dataops-dde-streamlit-browser-refresh.png' },
                  { type: 'file', name: 'ddesetup1.png' },
                  { type: 'file', name: 'externalvolume1.gif' },
                  { type: 'file', name: 'headercollaboration.png' },
                  { type: 'file', name: 'headerdatagovernance.png' },
                  { type: 'file', name: 'headerfinancialgovernance.png' },
                  { type: 'file', name: 'headergeospatial.png' },
                  { type: 'file', name: 'headersemistructured.png' },
                  { type: 'file', name: 'headertransform.png' },
                  { type: 'file', name: 'hex1.png' },
                  { type: 'file', name: 'hex2.png' },
                  { type: 'file', name: 'hex3.png' },
                  { type: 'file', name: 'hex4.png' },
                  { type: 'file', name: 'hex5.png' },
                  { type: 'file', name: 'hex6.png' },
                  { type: 'file', name: 'jupyter1.png' },
                  { type: 'file', name: 'jupyter2.png' },
                  { type: 'file', name: 'jupyter3.png' },
                  { type: 'file', name: 'jupyter_small.png' },
                  { type: 'file', name: 'react1.png' },
                  { type: 'file', name: 'react2.png' },
                  { type: 'file', name: 'react3.png' },
                  { type: 'file', name: 'reactafter.png' },
                  { type: 'file', name: 'reactapp.png' },
                  { type: 'file', name: 'reactbefore.png' },
                  { type: 'file', name: 'snowpark-101-flow.png' },
                  { type: 'file', name: 'snowpark101-datamodel.png' },
                  { type: 'file', name: 'snowpark_101_notebook_preview.png' },
                  { type: 'file', name: 'streamlit1.png' },
                  { type: 'file', name: 'streamlit2.png' },
                  { type: 'file', name: 'streamlit3.png' },
                  { type: 'file', name: 'streamlit4.png' },
                  { type: 'file', name: 'voc_dataflow.png' },
                  { type: 'file', name: 'voc_flow.png' },
                  { type: 'file', name: 'voc_jupyter_preview.png' },
                  { type: 'file', name: 'voc_sentiment1.png' },
                  { type: 'file', name: 'voc_streamlit_preview.png' },
                  { type: 'file', name: 'voiceofcustomer.png' },
                  { type: 'file', name: 'workload_deep_dive_datascience.png' },
                ],
              },
              { type: 'file', name: 'index.md' },
              { type: 'file', name: 'info.md' },
              { type: 'file', name: 'setup.md' },
            ],
          },
          { type: 'file', name: 'mkdocs.yml' },
          { type: 'file', name: 'requirements.txt' },
        ],
      },
    ],
  },
  {
    type: 'directory',
    name: 'dataops-dde',
    contents: [
      { type: 'file', name: 'post-setup.sh' },
      { type: 'file', name: 'pre-setup.sh' },
    ],
  },
  {
    type: 'directory',
    name: 'dataops-teardown',
    contents: [{ type: 'file', name: 'disable-teardown.template.sql' }],
  },
  { type: 'file', name: 'full-ci.yml' },
  { type: 'directory', name: 'images', contents: [{ type: 'file', name: 'Tasty_Bytes_Logo_horizontal-blue.png' }] },
  {
    type: 'directory',
    name: 'pipelines',
    contents: [
      {
        type: 'directory',
        name: 'includes',
        contents: [
          { type: 'file', name: 'bootstrap.yml' },
          {
            type: 'directory',
            name: 'config',
            contents: [
              { type: 'file', name: 'README.md' },
              { type: 'file', name: 'agent_tag.yml' },
              { type: 'file', name: 'stages.yml' },
              { type: 'file', name: 'variables.yml' },
            ],
          },
          {
            type: 'directory',
            name: 'local_includes',
            contents: [
              { type: 'file', name: 'check_connection.yml' },
              {
                type: 'directory',
                name: 'modelling_and_transformation',
                contents: [
                  { type: 'file', name: 'build_all_models.yml' },
                  { type: 'file', name: 'test_all_models.yml' },
                  { type: 'file', name: 'test_all_sources.yml' },
                ],
              },
              { type: 'file', name: 'print_context.yml' },
              { type: 'file', name: 'record_pipeline_start.yml' },
              {
                type: 'directory',
                name: 'solution',
                contents: [{ type: 'file', name: 'build_solution_homepage.yml' }],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'directory',
    name: 'scripts',
    contents: [
      { type: 'file', name: 'before_script_define_sole_wh.sh' },
      { type: 'file', name: 'record_pipeline_start.py' },
      { type: 'file', name: 'snowflake_connection_check.py' },
    ],
  },
  {
    type: 'directory',
    name: 'tastyBytesDriverApp',
    contents: [
      {
        type: 'directory',
        name: 'front-end',
        contents: [
          { type: 'file', name: '.env' },
          { type: 'file', name: '.gitignore' },
          { type: 'file', name: '.tool-versions' },
          { type: 'file', name: 'App.js' },
          { type: 'file', name: 'Checklist.js' },
          { type: 'file', name: 'CurrentInventory.js' },
          { type: 'file', name: 'CurrentInventoryItem.js' },
          { type: 'file', name: 'Earnings.js' },
          { type: 'file', name: 'EarningsDetail.js' },
          { type: 'file', name: 'FoodWaste.js' },
          { type: 'file', name: 'FoodWasteDetails.js' },
          {
            type: 'directory',
            name: 'Images',
            contents: [
              { type: 'file', name: 'FoodWaste.png' },
              { type: 'file', name: 'LaunchScreen.png' },
              { type: 'file', name: 'SignUp.png' },
              { type: 'file', name: 'TB_PoweredBySnowflake.png' },
            ],
          },
          { type: 'file', name: 'InventoryOrder.js' },
          { type: 'file', name: 'InventoryOrderItem.js' },
          { type: 'file', name: 'Launch.js' },
          { type: 'file', name: 'LocationDetails.js' },
          { type: 'file', name: 'LocationExpand.js' },
          { type: 'file', name: 'Locations.js' },
          { type: 'file', name: 'Menu.js' },
          { type: 'file', name: 'MenuItem.js' },
          { type: 'file', name: 'Navigation.js' },
          { type: 'file', name: 'Notifications.js' },
          { type: 'file', name: 'OrderDetails.js' },
          { type: 'file', name: 'OrderSummary.js' },
          { type: 'file', name: 'Orders.js' },
          { type: 'file', name: 'Profile.js' },
          { type: 'file', name: 'README.md' },
          { type: 'file', name: 'ReviewDetails.js' },
          { type: 'file', name: 'ReviewList.js' },
          { type: 'file', name: 'SelectLocations.js' },
          { type: 'file', name: 'SignIn.js' },
          { type: 'file', name: 'SignUp.js' },
          { type: 'file', name: 'Styles.js' },
          { type: 'file', name: 'UpdatedCurrentInventoryData.js' },
          { type: 'file', name: 'UpdatedInventoryOrderData.js' },
          { type: 'file', name: 'UpdatedSelectedLocations.js' },
          { type: 'file', name: 'WeekSchedule.js' },
          { type: 'file', name: 'app.json' },
          { type: 'file', name: 'babel.config.js' },
          { type: 'file', name: 'build.sh' },
          { type: 'file', name: 'index.js' },
          { type: 'file', name: 'metro.config.js' },
          { type: 'file', name: 'package-lock.json' },
          { type: 'file', name: 'package.json' },
          { type: 'file', name: 'yarn.lock' },
        ],
      },
    ],
  },
  {
    type: 'directory',
    name: 'tastybytes-dataops',
    contents: [
      {
        type: 'directory',
        name: '30 - vignettes',
        contents: [
          { type: 'file', name: 'README.md' },
          {
            type: 'directory',
            name: 'Workload Deep Dive',
            contents: [
              {
                type: 'directory',
                name: 'Data Science',
                contents: [
                  { type: 'file', name: 'README.md' },
                  {
                    type: 'directory',
                    name: 'Snowpark 101',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      { type: 'file', name: 'environment.yml' },
                      { type: 'file', name: 'tasty_bytes_snowpark_101.ipynb' },
                    ],
                  },
                  {
                    type: 'directory',
                    name: 'Voice of Customer',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      { type: 'file', name: 'SNO-SnowflakeLogo_blue.png' },
                      { type: 'file', name: 'aspect_wrds.txt' },
                      { type: 'file', name: 'custom_sentiment.txt' },
                      { type: 'file', name: 'en_core_web_sm.zip' },
                      { type: 'file', name: 'requirements_voc.txt' },
                      { type: 'file', name: 'tasty bytes banner.png' },
                      { type: 'file', name: 'tasty_bytes_customer_sentiment.ipynb' },
                      { type: 'file', name: 'tasty_bytes_customer_sentiment_setup.ipynb' },
                      { type: 'file', name: 'tasty_bytes_survey_app.py' },
                      {
                        type: 'directory',
                        name: 'vertical_themes',
                        contents: [
                          { type: 'file', name: 'CONTRIB.md' },
                          { type: 'file', name: 'default.po' },
                          { type: 'file', name: 'federal.po' },
                          {
                            type: 'directory',
                            name: 'text',
                            contents: [
                              {
                                type: 'directory',
                                name: 'en_US',
                                contents: [
                                  {
                                    type: 'directory',
                                    name: 'LC_MESSAGES',
                                    contents: [
                                      { type: 'file', name: 'default.mo' },
                                      { type: 'file', name: 'default.po' },
                                      { type: 'file', name: 'federal.mo' },
                                      { type: 'file', name: 'federal.po' },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              { type: 'file', name: 'README.md' },
            ],
          },
        ],
      },
      { type: 'file', name: 'README.md' },
    ],
  },
  {
    type: 'directory',
    name: 'tastybytes-main',
    contents: [
      { type: 'directory', name: '0 - demo video', contents: [{ type: 'file', name: 'README.md' }] },
      { type: 'directory', name: '10 - presentation', contents: [{ type: 'file', name: 'README.md' }] },
      {
        type: 'directory',
        name: '20 - setup',
        contents: [
          { type: 'file', name: 'README.md' },
          { type: 'file', name: 'setup_step_1_screenshot_walkthrough_tb.md' },
          { type: 'file', name: 'setup_step_2_snowflake_account_tb.sql' },
        ],
      },
      {
        type: 'directory',
        name: '30 - vignettes',
        contents: [
          { type: 'file', name: 'README.md' },
          {
            type: 'directory',
            name: 'Workload Deep Dive',
            contents: [
              {
                type: 'directory',
                name: 'Data Engineering',
                contents: [
                  {
                    type: 'directory',
                    name: 'External & Iceberg Tables',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      { type: 'file', name: 'external_tables_step_1_tb.sql' },
                      { type: 'file', name: 'iceberg_tables_step_2_tb.sql' },
                      { type: 'file', name: 'reset_step_3_external_iceberg_tables_tb.sql' },
                      {
                        type: 'directory',
                        name: 'setup',
                        contents: [
                          { type: 'file', name: 'README.md' },
                          { type: 'file', name: 'setup_external-iceberg_tb.sql' },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'directory',
                    name: 'Ingestion, Optimization and Automation',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      { type: 'file', name: 'data_engineering_dd_ioa_tb.sql' },
                    ],
                  },
                  { type: 'file', name: 'README.md' },
                ],
              },
              {
                type: 'directory',
                name: 'Data Science',
                contents: [
                  {
                    type: 'directory',
                    name: ' Voice of Customer',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      { type: 'file', name: 'SNO-SnowflakeLogo_blue.png' },
                      { type: 'file', name: 'aspect_wrds.txt' },
                      { type: 'file', name: 'custom_sentiment.txt' },
                      { type: 'file', name: 'data_scientist_auth_demo.json' },
                      { type: 'file', name: 'en_core_web_sm.zip' },
                      { type: 'file', name: 'requirements_voc.txt' },
                      { type: 'file', name: 'tasty bytes banner.png' },
                      { type: 'file', name: 'tasty_bytes_customer_sentiment.ipynb' },
                      { type: 'file', name: 'tasty_bytes_customer_sentiment_setup.ipynb' },
                      { type: 'file', name: 'tasty_bytes_survey_app.py' },
                      { type: 'file', name: 'tasty_bytes_survey_data_ingestion_step_1.sql' },
                      { type: 'file', name: 'tasty_bytes_voc_reset.sql' },
                      { type: 'file', name: 'tasty_bytes_voc_setup.sql' },
                    ],
                  },
                  { type: 'file', name: 'README.md' },
                  {
                    type: 'directory',
                    name: 'Snowpark 101',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      { type: 'file', name: 'data_scientist_auth.json' },
                      { type: 'file', name: 'environment.yml' },
                      { type: 'file', name: 'tasty_bytes_snowpark_101.ipynb' },
                    ],
                  },
                ],
              },
              {
                type: 'directory',
                name: 'Predictive Maintenance',
                contents: [
                  {
                    type: 'directory',
                    name: '0 - Setup',
                    contents: [{ type: 'file', name: 'LoadTruckNotebook.ipynb' }],
                  },
                  {
                    type: 'directory',
                    name: '1 - Real-time MF4 ingestion',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      {
                        type: 'directory',
                        name: 'images',
                        contents: [
                          { type: 'file', name: 'aws-keys.png' },
                          { type: 'file', name: 'aws-token-expire.png' },
                          { type: 'file', name: 'channel-details.png' },
                          { type: 'file', name: 'kill-process.png' },
                          { type: 'file', name: 'mf4-files-metadata.png' },
                          { type: 'file', name: 'python-streaming-script.png' },
                          { type: 'file', name: 'refresh-token.png' },
                          { type: 'file', name: 's3-bucket-create.png' },
                          { type: 'file', name: 's3-mf4-data.png' },
                          { type: 'file', name: 'sandbox-snowbiz.png' },
                          { type: 'file', name: 'snow-connect.png' },
                          { type: 'file', name: 'snow-sandbox-console-cred.png' },
                          { type: 'file', name: 'snow-sandbox-console.png' },
                          { type: 'file', name: 'snow-sandbox-cred.png' },
                          { type: 'file', name: 'snow-sandbox.png' },
                          { type: 'file', name: 'streaming-table.png' },
                          { type: 'file', name: 'truck-table.png' },
                        ],
                      },
                      { type: 'file', name: 'mf4reader.py' },
                      { type: 'file', name: 'requirements.txt' },
                      {
                        type: 'directory',
                        name: 'snowflake-sql',
                        contents: [{ type: 'file', name: 'mf4-snowpipe-streaming.sql' }],
                      },
                      { type: 'file', name: 'snowflake_connections.json' },
                      { type: 'file', name: 'snowpipe-streaming.jar' },
                      { type: 'file', name: 'source.properties' },
                    ],
                  },
                  {
                    type: 'directory',
                    name: '2 - Data Processing',
                    contents: [
                      { type: 'file', name: 'LoadTruckNotebook.ipynb' },
                      { type: 'file', name: 'README.md' },
                    ],
                  },
                  {
                    type: 'directory',
                    name: '3 - Failure Predictions',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      { type: 'file', name: 'data_scientist_auth_demo.json' },
                      { type: 'file', name: 'requirements_pm.txt' },
                      { type: 'file', name: 'tasty_bytes_predict_failure.ipynb' },
                      { type: 'file', name: 'tasty_bytes_predict_failure_setup.ipynb' },
                    ],
                  },
                  {
                    type: 'directory',
                    name: '4 - Fleet Analysis',
                    contents: [
                      { type: 'file', name: 'README.md' },
                      { type: 'file', name: 'snowflake_connection_template.json' },
                      {
                        type: 'directory',
                        name: 'src',
                        contents: [
                          { type: 'file', name: 'requirements.txt' },
                          {
                            type: 'directory',
                            name: 'streamlit',
                            contents: [
                              {
                                type: 'directory',
                                name: 'helper_functions',
                                contents: [
                                  { type: 'file', name: 'get_params.py' },
                                  { type: 'file', name: 'queries.py' },
                                  { type: 'file', name: 'session_connectivity.py' },
                                ],
                              },
                              {
                                type: 'directory',
                                name: 'images',
                                contents: [
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Better off Bread.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Cheeky Greek.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Freezing Point.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Guac N Roll.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Kitakata Ramen Bar.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Les Coins Des Crepes.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Nani_s Kitchen.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Not The Wurst Dogs.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Peking Truck.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Plant Palace.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Revenge of the Curds.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Smoky BBQ.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_Tasty Tibs.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_The Mac Shack.png' },
                                  { type: 'file', name: 'Tasty_Bytes_Truck_The Mega Melt.png' },
                                  { type: 'file', name: 'alert_icon.png' },
                                  { type: 'file', name: 'checkmark_icon.png' },
                                  { type: 'file', name: 'predictive_maintainance_dash.png' },
                                  { type: 'file', name: 'random_truck.jpeg' },
                                  { type: 'file', name: 'tasty_bytes.png' },
                                  { type: 'file', name: 'test.png' },
                                  { type: 'file', name: 'time_icon.png' },
                                  { type: 'file', name: 'truck_1.png' },
                                  { type: 'file', name: 'truck_12.png' },
                                  { type: 'file', name: 'truck_13.png' },
                                  { type: 'file', name: 'truck_14.png' },
                                  { type: 'file', name: 'truck_all.png' },
                                  { type: 'file', name: 'truck_critical.png' },
                                  { type: 'file', name: 'truck_critical.webp' },
                                  { type: 'file', name: 'truck_icon.png' },
                                  { type: 'file', name: 'truck_ok.png' },
                                  { type: 'file', name: 'truck_red.png' },
                                  { type: 'file', name: 'truck_redd.png' },
                                  { type: 'file', name: 'truck_ylw.png' },
                                ],
                              },
                              { type: 'file', name: 'mfg_app.py' },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  { type: 'file', name: 'README.md' },
                ],
              },
              { type: 'file', name: 'README.md' },
            ],
          },
          {
            type: 'directory',
            name: 'Zero to Snowflake',
            contents: [
              {
                type: 'directory',
                name: '1 - Financial Governance',
                contents: [
                  { type: 'file', name: 'README.md' },
                  { type: 'file', name: 'financial_governance_step_1_tb.sql' },
                ],
              },
              {
                type: 'directory',
                name: '2 - Transformation',
                contents: [
                  { type: 'file', name: 'README.md' },
                  { type: 'file', name: 'transformation_step_1_tb.sql' },
                ],
              },
              {
                type: 'directory',
                name: '3 - Semi-Structured Data',
                contents: [
                  { type: 'file', name: 'README.md' },
                  { type: 'file', name: 'semi_structured_step_1_tb.sql' },
                ],
              },
              {
                type: 'directory',
                name: '4 - Data Governance',
                contents: [
                  { type: 'file', name: 'README.md' },
                  { type: 'file', name: 'data_governance_step_1_tb.sql' },
                ],
              },
              {
                type: 'directory',
                name: '5 - Collaboration',
                contents: [
                  { type: 'file', name: 'README.md' },
                  { type: 'file', name: 'collaboration_step_1_tb.sql' },
                ],
              },
              {
                type: 'directory',
                name: '6 - Geospatial',
                contents: [
                  { type: 'file', name: 'README.md' },
                  { type: 'file', name: 'geospatial_step_1_tb.sql' },
                ],
              },
              { type: 'file', name: 'README.md' },
            ],
          },
        ],
      },
      {
        type: 'directory',
        name: '40 - analytics',
        contents: [
          { type: 'file', name: 'README.md' },
          {
            type: 'directory',
            name: 'Snowsight - Organization and City Metrics Dashboard',
            contents: [
              { type: 'file', name: 'README.md' },
              { type: 'file', name: 'snowsight_dashboard_org_city_metrics_build_walkthrough_tb.md' },
            ],
          },
        ],
      },
      { type: 'directory', name: '50 - reset', contents: [{ type: 'file', name: 'README.md' }] },
      {
        type: 'directory',
        name: '60 - purge',
        contents: [
          { type: 'file', name: 'README.md' },
          { type: 'file', name: 'purge_step_1_tb.sql' },
        ],
      },
      {
        type: 'directory',
        name: '70 - graphics',
        contents: [
          {
            type: 'directory',
            name: 'Logos',
            contents: [
              { type: 'file', name: 'README.md' },
              { type: 'file', name: 'Tasty_Bytes_Logo_horizontal-black.png' },
              { type: 'file', name: 'Tasty_Bytes_Logo_horizontal-blue.png' },
              { type: 'file', name: 'Tasty_Bytes_Logo_horizontal-white.png' },
              { type: 'file', name: 'Tasty_Bytes_Logo_primary-black.png' },
              { type: 'file', name: 'Tasty_Bytes_Logo_primary-blue.png' },
              { type: 'file', name: 'Tasty_Bytes_Logo_primary-white.png' },
              { type: 'file', name: 'Tasty_Bytes_Logo_vertical-black.png' },
              { type: 'file', name: 'Tasty_Bytes_Logo_vertical-blue.png' },
              { type: 'file', name: 'Tasty_Bytes_Logo_vertical-white.png' },
            ],
          },
          { type: 'file', name: 'README.md' },
          {
            type: 'directory',
            name: 'Trucks',
            contents: [
              { type: 'file', name: 'README.md' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Better off Bread.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Cheeky Greek.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Freezing Point.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Guac N Roll.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Kitakata Ramen Bar.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Les Coins Des Crepes.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Nani_s Kitchen.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Not The Wurst Dogs.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Peking Truck.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Plant Palace.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Revenge of the Curds.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Smoky BBQ.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_Tasty Tibs.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_The Mac Shack.png' },
              { type: 'file', name: 'Tasty_Bytes_Truck_The Mega Melt.png' },
            ],
          },
          {
            type: 'directory',
            name: 'Zoom Backgrounds',
            contents: [
              { type: 'file', name: 'README.md' },
              { type: 'file', name: 'Tasty Bytes VB_01.png' },
              { type: 'file', name: 'Tasty Bytes VB_02.png' },
              { type: 'file', name: 'Tasty Bytes VB_03.png' },
              { type: 'file', name: 'Tasty Bytes VB_04.png' },
              { type: 'file', name: 'Tasty Bytes VB_05.png' },
              { type: 'file', name: 'Tasty Bytes VB_06.png' },
            ],
          },
        ],
      },
      { type: 'file', name: 'README.md' },
    ],
  },
  { type: 'file', name: 'test-ci.yml' },
  { type: 'file', name: 'test.txt' },
  { type: 'file', name: 'validate-ci.yml' },
  { type: 'directory', name: 'vault-content', contents: [{ type: 'file', name: 'vault.template.yml' }] },
];

export const testMetadata = {
  opportunityId: '861249169274',
  customerName: 'Valkyrie',
  customerLogoUrl: 'https://upload.wikimedia.org/wikipedia/commons/1/16/Pegasus_woodcut_1715.png',
  solutionPrefix: 'Pegasus',
  solutionTemplateName: 'Pegasus_Test',
  solutionTemplateId: '123',
  consumerUsername: 'ClareTest',
  consumerUserId: '321',
};
