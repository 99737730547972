import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ProjectListing, { ListingItem } from 'views/ProjectListing';
import NotFoundView from 'views/NotFoundView';
import { testManifest, testMetadata } from 'testFiles';
import { RootBoundary } from 'components/ErrorBoundary';

interface MetadataListingItem {
  solutionTemplateName: string;
  customerLogoUrl: string;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        id: 'project-listing',
        path: ':listingId/-/tree/*?',
        element: <ProjectListing />,
        errorElement: <RootBoundary />,
        loader: async ({ params }) => {
          //TODO: get manifest from app state or from public bucket if undefined??
          console.log(params.listingId);
          console.log(params['*']);

          let metadataExists = false;
          let manifestExists = false;

          let manifest: ListingItem[] = [];
          let metadata: MetadataListingItem;

          if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
            console.log('Running on localhost, using local test file');
            manifest = testManifest as ListingItem[];
            metadata = testMetadata as MetadataListingItem;
          } else {
            const metadataFromS3 = await fetch(
              `https://${location.hostname}/shares/v1/${params.listingId}/metadata.json`,
            );
            console.log('x-cache', metadataFromS3.headers.get('X-Cache'));

            if (metadataFromS3.headers.get('X-Cache') === 'Error from cloudfront' && params.listingId) {
              console.log('throwing x cache');
              // throw json({ status: 404, statusText: 'InProgress' });
              metadataExists = true;
            }

            const manifestFromS3 = await fetch(
              `https://${location.hostname}/shares/v1/${params.listingId}/manifest.json`,
            );

            if (manifestFromS3.headers.get('X-Cache') === 'Error from cloudfront' && params.listingId) {
              console.log('throwing x cache');
              // throw json({ status: 404, statusText: 'InProgress' });
              manifestExists = true;
            }

            manifest = await manifestFromS3.json();
            metadata = await metadataFromS3.json();
          }

          const filePath = params['*'] || '';

          console.log(`filePath is: ${filePath}`);

          let projectName = 'Test Project';
          let projectLogoUrl = '';

          let contents: ListingItem[] = manifest;

          projectName = metadata.solutionTemplateName;
          projectLogoUrl = metadata.customerLogoUrl;

          const ancestors = { [projectName]: `/${params.listingId}/-/tree/` };

          if (filePath) {
            const filePathParts = filePath.split('/');
            filePathParts.forEach((part) => {
              contents.forEach((item) => {
                if (item.name === part && item.contents) {
                  contents = item.contents;
                  const itemPath =
                    ancestors[projectName] + filePathParts.slice(0, filePathParts.indexOf(part) + 1).join('/') + '/';
                  console.log(`itemPath for ${item.name} is: ${itemPath}`);
                  ancestors[item.name] = itemPath;
                }
              });
            });
          }

          let inProgress = false;

          if (metadataExists && manifestExists === false) {
            inProgress = true;
          }

          return { ancestors, projectName, projectLogoUrl, contents, inProgress };
        },
      },
      {
        path: '*',
        element: <NotFoundView />,
      },
    ],
  },
]);

const container = document.getElementById('root');

if (container != null) {
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
